import React from 'react'
import PropTypes from 'prop-types'
import './all.sass'

const ThoughtEntry = ({ gridItems }) => (
 
    <div>
      {gridItems.map((item, index) => (
        <div key={index}>
            <div className={`thought ${item.highlight ? "highlight has-text-black":""}`}>
              { item.title && <div className="thought-title">
                {item.title}
              </div>}
                <div className="thought-content">
                  {item.thought}
                </div>

            </div>
        </div> 
      ))}
    </div>
)

ThoughtEntry.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      thought: PropTypes.string,
      highlight: PropTypes.bool,
    })
  ),
}

export default ThoughtEntry
