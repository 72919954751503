import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import '../components/all.sass'



import SEO from "../components/SEO"
import Navlist from '../components/Navlist'
import Footer from '../components/Footer'
import ThoughtEntry from '../components/ThoughtEntry'

export const ThoughtsPageTemplate = ({
  heading,
  thoughts
}) => (
 
    <section className="section section--gradient">
      <div className="">
        <div className="content">
          <div className="is-fluid">
            <h3>{heading}</h3>
            <ThoughtEntry gridItems={thoughts} />
          </div>
        </div>
      </div>
    </section>
  
)

ThoughtsPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  thoughts: PropTypes.array
}

const ThoughtsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <div>
      <SEO uniqueTitle={frontmatter.title} uniqueDescription={frontmatter.description} />
      <div className="has-background-black">
      <div
      className="full-width-image-container margin-top-0"
        style={{
         backgroundImage: `url(${
          !!frontmatter.image.childImageSharp ? frontmatter.image.childImageSharp.fluid.src : frontmatter.image
          })`
        }}  
    >
      <h2
        className="has-text-weight-bold"
        style={{
          padding: '1rem',
        }}
      >
        {frontmatter.title}
      </h2>
      
      <section className="menu">
        <ul className="navbar">
        <Navlist />
        </ul>
      </section>
      <p 
        className="description has-text-weight-bold has-text-black">
        {frontmatter.description}
      </p>
      
    </div>
          <div style={{ color: `#fff`}}>
            <ThoughtsPageTemplate
              title={frontmatter.title}
              heading={frontmatter.heading}
              description={frontmatter.description}
              image={frontmatter.image}
              thoughts={frontmatter.thoughts}
            />
          </div>
          </div>
        <Footer />
    </div>
  )
}

ThoughtsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ThoughtsPage

export const thoughtsPageQuery = graphql`
  query ThoughtsPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "thoughts-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        description
        thoughts {
          title
          thought
          highlight
        }
      }
    }
  }
`
